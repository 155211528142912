<template>
  <div id="header" class="header nobg">
    <div class="banxin">
      <a href="https://bmobapp.com/" class="logo">
        <img src="@/assets/img/logo.png" alt="比目后端云" class="logo1" title="Bmob后端云-让移动开发更简单">
        <img src="@/assets/img/logo1.png" alt="比目后端云" class="logo2" title="Bmob后端云-让移动开发更简单">
      </a>
      <a href="/" class="logo logo_min">
        <img src="@/assets/img/logo_1.png" alt="比目后端云" class="logo1" title="Bmob后端云-让移动开发更简单">
        <img src="@/assets/img/logo1_1.png" alt="比目后端云" class="logo2" title="Bmob后端云-让移动开发更简单">
      </a>
      <div id="min-nav" class="header-right">
        <!-- btns -->
        <div class="login-register">
          <div v-if="!isLogin">
            <a id="btn-login" :class="$route.path==='/login'?'active':''" href="/login">登录</a>
            <a class="btn-r" :class="$route.path==='/register'?'active':''" href="/register">注册</a>
          </div>
          <div v-else>
            <a id="" class="enter btn-r" target="_self" :href="createUrl('app/list')">我的控制台</a>
          </div>
        </div>
        <!-- nav -->
        <ul v-if="$route.path.includes('/repository')" id="nav" class="nav">
          <li><span /><a :href="$store.getters.docUrl + '/'">文档</a></li>
          <li class="on"><span /><a href="/repository/index">知识库</a></li>
          <li><span /><a href="https://github.com/bmob">开源</a></li>
        </ul>
        <ul v-else-if="['/about','/service','/privacy','/friendlink','/partners','/school'].indexOf($route.path)===-1 && !$route.path.includes('/site/news')" id="nav" class="nav">
          <li :class="$route.path==='/'?'on':''"><span /><a href="/">首页</a></li>
          <li :class="['/cloud','/function','/weixin','/sms'].indexOf($route.path)>-1?'on':''">
            <span /><a :href="createUrl('cloud')" class="hidden-xs">产品</a>
            <ul class="box-menu">
              <li><a :href="createUrl('cloud')">云数据库</a></li>
              <li><a :href="createUrl('function')" class="new_sale">云函数</a></li>
              <li><a :href="createUrl('weixin')" class="new_sale">小程序<span>热</span></a></li>
              <!-- <li><a href="https://game.bmob.cn/" class="new_product" target="_blank">实时游戏<span >new</span></a></li>-->
              <li><a :href="createUrl('sms')" class="new_sale">短信验证码<span>促</span></a></li>
              <li><a :href="createUrl('ai')" class="new_sale">人工智能<span>新</span></a></li>
              <li><a :href="createUrl('hook')" class="new_sale">数据钩子<span>新</span></a></li>
              <li><a :href="createUrl('harmoney')" class="new_sale">鸿蒙SDK<span>新</span></a></li>
            </ul>
          </li>
          <li :class="$route.path.includes('/shop')?'on':''"><span /><a href="/shop/index">源码</a>
          </li>
          <li :class="$route.path==='/prices'?'on':''"><span /><a href="/prices">价格</a></li>
          <li><span /><a :href="$store.getters.docUrl" target="_blank">文档</a></li>
          <!-- <li :class="$route.path==='/downloads'?'on hidden-xs':'hidden-xs'"><span /><a href="/downloads">下载</a></li> -->
          <li class="hidden-xs"><span /><a :href="$store.getters.docUrl + '/video/index.html'" target="_blank">实战视频</a></li>
        </ul>
        <!--  -->
        <ul v-else id="nav" class="nav" style="width:660px">
          <li :class="$route.path==='/about'?'on':''"><span /><a href="/about">公司简介</a>
          </li>
          <li :class="$route.path.includes('/site/news')?'on':''">
            <span /><a href="/site/news">新闻动态</a>
          </li>
          <!-- <li><span /><a href="http://www.lagou.com/gongsi/2480.html" target="_blank">加入我们</a></li> -->
          <li :class="$route.path==='/service'?'on':''"><span /><a href="/service">服务条款</a></li>
          <li :class="$route.path==='/privacy'?'on':''"><span /><a href="/privacy">隐私政策</a></li>
          <li :class="$route.path==='/school'?'on':''"><span /><a href="/school">客户院校</a></li>
          <!--   <li :class="$route.path==='/partners'?'on':''">
                        <span />
                        <a href="/partners">合作伙伴</a>
                    </li>
                    <li :class="$route.path==='/friendlink'?'on':''">
                        <span />
                        <a href="/friendlink">友情链接</a>
                    </li> -->
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { getToken, removeToken } from '@/utils/auth' // get token from cookie
import { checkLogin } from '@/api/backend'
export default {
  name: 'Header',
  props: {},
  data: function() {
    return {
      isLogin: getToken()
    }
  },
  mounted() {
    checkLogin().then(res => {
      if (res.msg == '用户未登录') {
        this.isLogin = false
        removeToken()
      }
    })

    // 跳转https
    if (location.protocol != 'https:' && (location.hostname == 'www.bmob.cn' || location.hostname == 'bmob.cn' || location.hostname == 'bmobapp.com')) {
      location.href = 'https:' + window.location.href.substring(window.location.protocol.length)
    }

    // eslint-disable-next-line no-undef
    responsiveNav('#min-nav')
    const b = $(window).width()
    if (b > 640) {
      $('#nav>li').hover(function() {
        $(this).children('ul').stop().fadeToggle(300)
        $(this).stop().toggleClass('nav-hover')
      })
    }
    $('#weixin').hover(function() { $('#weixin_qr').toggleClass('gaoliang') })
    if (b > 1000) {
      $('.user-msg').hover(function() {
        $(this).children('.logout').stop().slideDown(500)
        $(this).children('.glyphicon-menu-down').addClass('rota')
      }, function() {
        $(this).children('.logout').stop().slideUp(500)
        $(this).children('.glyphicon-menu-down').removeClass('rota')
      })
    } else {
      $('.user-msg').click(function() {
        $(this).children('.logout').stop().slideToggle(500)
      })
    }
    const that = this
    $('.user-msg .logout').click(function() {
      that.$store
        .dispatch('user/logout')
        .then(() => {
          that.$router.push({
            path: '/'
          })
        })
    })
  },
  methods: {
    createUrl: function(route, params) {
      let paramStr = ''
      for (const key in params) {
        paramStr += '&' + key + '=' + params[key]
      }
      if (paramStr) {
        paramStr = '?' + paramStr.substring(1, paramStr.length)
      }
      if (route.indexOf('/') !== 0) {
        route = '/' + route
      }
      return process.env.VUE_APP_BASE_URL + route + paramStr
    }
  }
}
</script>
